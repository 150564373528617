<script setup lang="ts">
const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.5 26C22.0228 26 26.5 21.5228 26.5 16C26.5 10.4772 22.0228 6 16.5 6C10.9772 6 6.5 10.4772 6.5 16C6.5 21.5228 10.9772 26 16.5 26ZM18.2307 12.0014L17.3663 10.5023C16.9815 9.835 16.0185 9.835 15.6337 10.5023L14.7693 12.0014C14.5906 12.3112 14.2601 12.502 13.9024 12.5018L12.172 12.5009C11.4017 12.5005 10.9202 13.3345 11.3057 14.0014L12.1717 15.4995C12.3507 15.8092 12.3507 16.1908 12.1717 16.5005L11.3057 17.9986C10.9202 18.6655 11.4017 19.4995 12.172 19.4991L13.9024 19.4982C14.2601 19.498 14.5906 19.6888 14.7693 19.9986L15.6337 21.4977C16.0185 22.165 16.9815 22.165 17.3663 21.4977L18.2307 19.9986C18.4094 19.6888 18.7399 19.498 19.0976 19.4982L20.828 19.4991C21.5983 19.4995 22.0798 18.6655 21.6943 17.9986L20.8283 16.5005C20.6493 16.1908 20.6493 15.8092 20.8283 15.4995L21.6943 14.0014C22.0798 13.3345 21.5983 12.5005 20.828 12.5009L19.0976 12.5018C18.7399 12.502 18.4094 12.3112 18.2307 12.0014Z"
            :fill="'url(#paint0_linear_308_537' + randomId + ')'"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16ZM16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z"
            :fill="'url(#paint1_linear_308_537' + randomId + ')'"
        />
        <defs>
            <linearGradient
                :id="'paint0_linear_308_537' + randomId"
                x1="16.5"
                y1="0"
                x2="16.5"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_308_537' + randomId"
                x1="16.5"
                y1="0"
                x2="16.5"
                y2="32"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
        </defs>
    </svg>
</template>
